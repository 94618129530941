<script>
import { format } from "date-fns";
import { hasRouteSearch } from "@/mixins/routerParams";

const EMPTY_SEARCH = {
  orderNumber: undefined,
  customerName: undefined,
  customerEmail: undefined,
  priceLow: undefined,
  priceHigh: undefined,
  startDate: undefined,
  endDate: undefined,
};

export default {
  name: "AdvancedSearch",
  format,
  components: {
    RaiDatePicker: () => import("@/components/util/RaiDatePicker"),
  },
  mixins: [hasRouteSearch],
  props: {
    parsedFilter: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {};
  },
  computed: {
    search() {
      if (this.parsedFilter && this.parsedFilter.isAdvanced) {
        return this.parsedFilter.data;
      }

      return this.buildEmptySearch();
    },
    payload() {
      const payload = {};
      if (this.search.orderNumber) {
        payload.orderNumber = this.search.orderNumber;
      }
      if (this.search.customerName) {
        payload.customerName = this.search.customerName;
      }
      if (this.search.customerEmail) {
        payload.email = this.search.customerEmail;
      }
      if (this.search.priceLow) {
        payload.price = [this.search.priceLow];
      }
      if (this.search.priceHigh) {
        payload.price = payload.price
          ? [...payload.price, this.search.priceHigh]
          : [undefined, this.search.priceHigh];
      }
      if (this.search.startDate) {
        payload.date = [this.search.startDate];
      }
      if (this.search.endDate) {
        payload.date = payload.date
          ? [...payload.date, this.search.endDate]
          : [undefined, this.search.endDate];
      }

      return payload;
    },
  },
  methods: {
    buildEmptySearch() {
      return Object.assign({}, EMPTY_SEARCH);
    },
    cancel() {
      this.$emit("cancel");
    },
    update() {
      // TODO - this should be not payload but a normal object, not some hacky way of parsing the input (when not needed)!
      this.$emit("update", this.payload);
    },
  },
};
</script>

<template>
  <v-form ref="advancedSearchForm" class="d-flex flex-column">
    <div>
      <div class="text-overline">Order</div>
      <v-divider class="flex-shrink-0"></v-divider>
    </div>
    <div class="d-sm-flex">
      <div class="mr-sm-2">
        <v-text-field
          v-model="search.orderNumber"
          :label="'Order number'"
          autocomplete="no"
          hide-details
          @keyup.enter.exact="update"
        />
      </div>
    </div>
    <div class="d-sm-flex mt-2">
      <div class="align-self-center mt-4 pt-1 text-body-1">Total price</div>
      <div class="ml-sm-2">
        <v-text-field
          v-model="search.priceLow"
          prefix="$"
          type="number"
          label="Low"
          autocomplete="no"
          hide-details
          @keyup.enter.exact="update"
        />
      </div>
      <div class="ml-sm-2">
        <v-text-field
          v-model="search.priceHigh"
          prefix="$"
          type="number"
          label="High"
          autocomplete="no"
          hide-details
          @keyup.enter.exact="update"
        />
      </div>
    </div>
    <div class="d-sm-flex mt-2">
      <div class="align-self-center mt-4 pt-1 text-body-1">Order date</div>
      <div class="ml-sm-2">
        <rai-date-picker
          v-model="search.startDate"
          hide-details
          append-icon="$vuetify.icons.calendar"
          label="Start"
          :max="search.endDate || $options.format(new Date(), `yyyy-MM-dd`)"
        />
      </div>
      <div class="ml-sm-2">
        <rai-date-picker
          v-model="search.endDate"
          hide-details
          append-icon="$vuetify.icons.calendar"
          label="End"
          :min="search.startDate || null"
          :max="$options.format(new Date(), `yyyy-MM-dd`)"
        />
      </div>
    </div>

    <div class="mt-6">
      <div class="text-overline">Customer</div>
      <v-divider class="flex-shrink-0"></v-divider>
    </div>
    <div class="d-sm-flex">
      <div class="mr-sm-2">
        <v-text-field
          v-model="search.customerName"
          :label="'Customer name'"
          autocomplete="no"
          hide-details
          @keyup.enter.exact="update"
        />
      </div>
      <div class="mr-sm-2">
        <v-text-field
          v-model="search.customerEmail"
          :label="'Customer email'"
          type="email"
          autocomplete="no"
          hide-details
          @keyup.enter.exact="update"
        />
      </div>
    </div>
    <div class="d-sm-flex mt-4">
      <v-spacer />
      <v-btn class="elevation-0" text @click="cancel">Cancel</v-btn>
      <v-btn class="ml-4 elevation-0" color="accent" text @click="update">
        Search
      </v-btn>
    </div>
  </v-form>
</template>

<style></style>
